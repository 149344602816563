import { Col, Row } from "reactstrap";
import typography from "../typography";
import {
  convertDateTime,
  dateTimeFormat,
} from "../../../utils/dateFormatUtils";
import { Affirmations } from "../../../constants";

const customFormat = dateTimeFormat.monthLetterDateFormat;
export default function DetailsBox({
  data = {
    date: "",
    category: "",
    BLSDuration: "",
    feedback: "",
  },
}) {
  return (
    <>
      <Row className="details-inner-con mx-auto">
        <Row>
          <Col md="6" xs="6">
            <div className="mb-3 affirmation-con">
              <h6 className="standard-typo-14-400 primary-color mb-0">
                {typography.DATE}
              </h6>
              <p className="standard-typo-16-600 ">
                {convertDateTime({ date: data?.createdAt, customFormat }) ||
                  "-"}
              </p>
            </div>
          </Col>
          <Col md="6" xs="6">
            <div className="mb-3 affirmation-con">
              <h6 className="standard-typo-14-400 primary-color mb-0 ">
                {typography.CATEGORY}
              </h6>
              <p className="standard-typo-16-600 word-wrap-break">
                {Affirmations.getDisplayTextKey(data?.affirmationType) || "-"}
              </p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md="6" xs="6">
            {" "}
            <div className="affirmation-con">
              <h6 className="standard-typo-14-400 primary-color mb-0">
                {typography.BLS_DURATION}
              </h6>
              <p className="standard-typo-16-600">
                {data?.BLSTherapy?.duration
                  ? `${data?.BLSTherapy?.duration / 60} Min`
                  : "-"}
              </p>
            </div>
          </Col>
          <Col md="6" xs="6">
            <div className="affirmation-con">
              <h6 className="standard-typo-14-400 primary-color mb-0">
                {typography.FEEDBACK}
              </h6>
              <p className="standard-typo-16-600">
                {data?.BLSTherapy?.duration
                  ? data?.BLSTherapy?.feedbackFormSubmittedAt ==
                    (null || undefined)
                    ? "Not Submitted"
                    : "Submitted"
                  : "-"}
              </p>
            </div>
          </Col>
        </Row>
      </Row>
    </>
  );
}
