import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import { Col, Container, Label, Row } from "reactstrap";

import { PageTitles } from "../../constants";
import { HeadingWithBackArrow } from "../../components/HeadingWithBackArrow";
import BackArrowIcon from "../../assets/images/app/common/aou-backarrow-icon.svg";

import typography from "./typography";
import { APP_ROUTES } from "../../helpers/routeHelpers";
import { PasswordInput } from "../../components/Input";
import {
  initialValuesPassChange,
  validationSchemaPassChange,
} from "./FormikConfig";
import FormikErrorText from "../../components/FormikErrorText/FormikErrorText";
import { ConfirmationModal } from "../../components/CustomModal";
import ModalSuccessImage from "../../assets/images/app/common/aou-modal-success-img.svg";

import { changePassword } from "../../api/api.service";
import { useDispatch } from "react-redux";
import { SET_TOKEN, getMe } from "../../store/actions";
import { CustomButton } from "../../components/CustomButton";

export default function ChangePassword() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [updatedConfirmation, setUpdateConfirmation] = useState(false);
  const [isPasswordUpdating, setIsPasswordUpdating] = useState(false);

  const backButtonHandler = () => {
    history.push(APP_ROUTES.BLS_FEEDBACK);
  };

  const updateButtonHandler = () => {
    updatePasswordHandler(formik.values);
  };

  const formik = useFormik({
    initialValues: initialValuesPassChange,
    validationSchema: validationSchemaPassChange,
    onSubmit: (values) => {
      setUpdateConfirmation(true);
    },
  });

  const updatePasswordHandler = async (values) => {
    setUpdateConfirmation(false);
    setIsPasswordUpdating(true);
    try {
      let bodyData = {
        oldPassword: values.password,
        newPassword: values.confirmPassword,
      };
      let res = await changePassword(bodyData);
      formik.resetForm();
      toast.success(typography.PASSWORD_UPDATED_SUCCESSFULLY);
      dispatch({ type: SET_TOKEN, token: res.authToken });
      await dispatch(getMe(res.authToken));
    } finally {
      setIsPasswordUpdating(false);
      // setUpdateConfirmation(false)
    }
  };

  return (
    <>
      <div className="page-content">
        <Helmet>
          <title>{PageTitles.CHANGE_PASSWORD}</title>
        </Helmet>
        <Container fluid className="edit-profile">
          <form className="mt-5" onSubmit={formik.handleSubmit}>
            <div className="d-flex align-items-center ">
              <div
                className="me-2 arrow-icon-con cursor-pointer"
                onClick={backButtonHandler}
              >
                <img src={BackArrowIcon} alt="icon" />
              </div>
              <div className="standard-heading-22-600">
                {typography.CHANGE_PASSWORD}
              </div>
            </div>
            <div className="edit-profile-content p-5 ">
              <Row>
                <Col xs="12" md="12" lg="12" xl="12" className="mt-auto ">
                  <div className="change-pass-input-con">
                    <Label>{typography.CURRENT_PASSWORD}</Label>
                    <div className="mb-4">
                      <PasswordInput
                        placeholder={typography.ENTER_PASSWORD}
                        className="hide-default-icon password-input-field"
                        name="password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                      />
                      <FormikErrorText
                        formikInstance={formik}
                        fieldName={"password"}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs="12" md="12" lg="12" xl="12" className="mt-auto ">
                  <div className="change-pass-input-con">
                    <Label>{typography.NEw_PASSWORD}</Label>
                    <div className="mb-4">
                      <PasswordInput
                        placeholder={typography.ENTER_NEW_PASSWORD}
                        className="hide-default-icon"
                        name="newPassword"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.newPassword}
                      />
                      <FormikErrorText
                        formikInstance={formik}
                        fieldName={"newPassword"}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs="12" md="12" lg="12" xl="12" className="mt-auto ">
                  <div className="change-pass-input-con">
                    <Label>{typography.REENTER_PASSWORD}</Label>
                    <div className="mb-4">
                      <PasswordInput
                        placeholder={typography.ENTER_REENTER_PASSWORD}
                        className="hide-default-icon"
                        name="confirmPassword"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.confirmPassword}
                      />
                      <FormikErrorText
                        formikInstance={formik}
                        fieldName={"confirmPassword"}
                      />
                    </div>
                  </div>
                </Col>
                <div>
                  <CustomButton
                    loading={isPasswordUpdating}
                    type="submit"
                    color="primary"
                    title={typography.UPDATE}
                    className="edit-profile-btn"
                  />
                </div>
              </Row>
            </div>
          </form>
        </Container>
        <ConfirmationModal
          isOpen={updatedConfirmation}
          hasCustomConfig
          size="md"
          onNo={() => setUpdateConfirmation(false)}
          onYes={() => updateButtonHandler()}
          customTitle={typography.UPDATE_PASSWORD_ASSURANCE}
          customYesBtnText={typography.YES}
          customNoBtnText={typography.NO}
          customImageSrc={ModalSuccessImage}
        />
      </div>
    </>
  );
}
