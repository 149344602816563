import { ToggleInput } from "../ToggleInput";
import BackArrowIcon from "../../assets/images/app/common/aou-backarrow-icon.svg";
import { CustomButton } from "../CustomButton";

const toggleConfig = {
  trueContent: "",
  falseContent: "",
};
export default function HeadingWithBackArrow({
  headingTitle = "",
  backArrowClickHandler = () => {},
  toggleButtonConfig = toggleConfig,
  toggleValue,
  buttonTitle = "",
  buttonHandler = () => {},
  isLoading = false,
  toggleHandler = () => {},
  isToggleDisabled = false,
  isButtonLoading = false,
}) {
  return (
    <>
      <div className="mb-4 d-flex align-items-center justify-content-between heading-back-btn-con">
        <div className="d-flex align-items-center ">
          <div className="me-2 arrow-icon-con" onClick={backArrowClickHandler}>
            <img src={BackArrowIcon} alt="icon" />
          </div>
          <div className="standard-heading-22-600">{headingTitle}</div>
        </div>
        <div>
          {/* {buttonTitle === "" ? ( */}
          <ToggleInput
            isChecked={toggleValue}
            trueContent={toggleButtonConfig.trueContent}
            falseContent={toggleButtonConfig.falseContent}
            toggleHandler={toggleHandler}
            isLoading={isToggleDisabled}
          />
          {/* ) : (
            <CustomButton
              loading={isButtonLoading}
              type="submit"
              // onClick={buttonHandler}
              color="primary"
              title={buttonTitle}
              className="edit-profile-btn"
              // className="w-100 "
            />
          )} */}
        </div>
      </div>
    </>
  );
}
