import { injectBaseConstantMethods } from "./BaseConstants";

const Affirmations = {
  Responsibility: "Responsibility",
  Action: "Action",
  Power: "Power",
  SelfEsteem: "SelfEsteem",
  IncreaseConfidence: "IncreaseConfidence",
  IncreaseHappiness: "IncreaseHappiness",
  DecreaseStress: "DecreaseStress",
  Safety: "Safety",
  DecreaseAnxiety: "DecreaseAnxiety",
  DecreaseDepression: "DecreaseDepression",
  BurnOutAtWork: "BurnOutAtWork",
  PhysicalAppearance: "PhysicalAppearance",
  HealthyRelationships: "HealthyRelationships",
  School: "School",
  CreateYourOwn: "CreateYourOwn",
};

const displayTextKeys = {
  [Affirmations.Responsibility]: "Responsibility",
  [Affirmations.Action]: "Action",
  [Affirmations.Power]: "Power",
  [Affirmations.SelfEsteem]: "SelfEsteem",
  [Affirmations.IncreaseConfidence]: "Increase Confidence",
  [Affirmations.IncreaseHappiness]: "Increase Happiness",
  [Affirmations.DecreaseStress]: "Decrease Stress",
  [Affirmations.Safety]: "Safety",
  [Affirmations.DecreaseAnxiety]: "Decrease Anxiety",
  [Affirmations.DecreaseDepression]: "Decrease Depression",
  [Affirmations.BurnOutAtWork]: "BurnOut at Work",
  [Affirmations.PhysicalAppearance]: "Physical Appearance",
  [Affirmations.HealthyRelationships]: "Healthy Relationships",
  [Affirmations.CreateYourOwn]: "Create your own affirmation",
  [Affirmations.School]: "School",
};

const labelClass = {
  [Affirmations.Responsibility]: "Responsibility",
  [Affirmations.Action]: "Action",
  [Affirmations.Power]: "Power",
  [Affirmations.SelfEsteem]: "SelfEsteem",
  [Affirmations.IncreaseConfidence]: "Increase Confidence",
  [Affirmations.IncreaseHappiness]: "Increase Happiness",
  [Affirmations.DecreaseStress]: "Decrease Stress",
  [Affirmations.Safety]: "Safety",
  [Affirmations.DecreaseAnxiety]: "Decrease Anxiety",
  [Affirmations.DecreaseDepression]: "Decrease Depression",
  [Affirmations.BurnOutAtWork]: "BurnOut at Work",
  [Affirmations.PhysicalAppearance]: "Physical Appearance",
  [Affirmations.HealthyRelationships]: "Healthy Relationships",
  [Affirmations.CreateYourOwn]: "Create your own affirmation",
  [Affirmations.School]: "School",
};

export default injectBaseConstantMethods(
  Affirmations,
  displayTextKeys,
  labelClass
);
